<template>
  <div class="school-grid">
    <div 
      class="school-grid-item" 
      v-for="school in sortedSchools" 
      :key="school.id"
      @click="selectSchool(school.id)"
    >
      <img :src="school.logo" :alt="school.name" />
      <div class=school-name>{{ school.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['schools'], 
  computed: {
    sortedSchools() {
      return [...this.schools].sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  
  methods: {
    selectSchool(schoolId) {
      setTimeout(() => {
        this.$emit('schoolSelected', schoolId);
      }, 400); // a slight delay for CSS animation
    }
  }
};
</script>

<style scoped>
.school-grid {
  padding-top: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 3em;
  animation: fadeIn 2s forwards;
}

.school-grid-item {
  text-align: center;
  cursor: pointer;
  user-select: none;
  box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(0.2, 0.2, 0.2, 0.2);
  padding-top: 1em;
   padding-bottom: 0.2em;
   background-color: #f0f0f0;
}

.school-grid-item:active {
box-shadow: inset 0.1em 0.1em 0.1em 0.1em rgba(0.2, 0.2, 0.2, 0.2);
}

.school-grid-item:hover .school-name {
  opacity: 1;
}

.school-grid-item img {
  width: 6.5em;
  height: auto;
  user-select: none;
  pointer-events: none;
  transition: transform 0.5s ease;
  transform: translateY(15%);
  
}

.school-grid-item:hover img {
  transform: scale(1.1);
  transition: transform 0.7s ease;
  
}

.school-name {
  padding-top: 0.7em;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
  color: #848484;
}

@media (min-width: 1024px) {
  .school-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1024px) {
	.school-grid {
	padding-top: 15vh;
	}

  .school-grid-item img {
  transform: scale(1.1);
  }
  .school-grid-item .school-name {
  opacity: 1;
  }
}

@keyframes fadeIn {
    0% {
        transform: translateY(0%);
        opacity: 0;
    }
60% {
  transform: translateY(2%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

</style>
