<template>
  <div class="nav">
  <img id="toolbox" height="58" src="img/toolbox.png" alt="Hamwic Toolbox" />
    <img id="HET" width="120" height="58" src="img/HET-Horizontal-Large-300x144.png" alt="Hamwic Education Trust" />
    <div class="tooltitle">
      {{ title }}
      <div class="subtext" v-html="subtext"></div>
    </div>
    <div class="explain">
      <span v-html="explanation"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Toolbox',
    },
    subtext: {
      type: String,
      default: '',
    },
    explanation: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
/* Styles specific to the Navbar component */
#toolbox {
position: absolute;
display: none;
right: 7em;
padding-bottom: 0.5em;
}
.nav {
  padding-top: 0.2em;
  padding-bottom: 1em;
  padding-left: 0;
  margin-left: -20px;
  margin-right: -20px;
  border-bottom: 4px solid #8F7212;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  z-index: 50;
  box-shadow: 0 0.5em 0.5em 0 rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.75s forwards;
    }
	
@keyframes fadeIn {
  0% {
    transform: translateY(-300%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.tooltitle {
  color: #bca260;
  font-family: 'AvenirLTStd-Roman';
  display: inline;
  font-size: 1.6em;
  font-weight: 500;
  position: relative;
  cursor: default;
}
.subtext {
  color: #848484;
  font-size: 0.5em;
  margin-left: 0.5em;
  font-style: italic;
  text-align: right;
  opacity: 1;
}
.explain {
  opacity: 0;
  margin-left: 3em;
  width: 36em;
  font-size: 0.8em;
  font-family: 'AvenirLTStd-Roman';
  color: #848484;
  cursor: default;
  transition: opacity 0.75s ease;
  text-justify: inter-character;
}
    @media (max-width: 1024px) {
      .nav {
        max-height: 8vh;
        padding-top: 2vh;
        align-items: center;
		position: fixed;
        width: 110vw;
      }
      
      #HET {
        margin-left: 2em;
      } 
      .explain { 
       display: none;
      }
      
      .nav:hover .explain { 
        display: none;
      }
	  #toolbox {
	  display: none;
	  }
    }
</style>
