<template>
  <div id="app">
    <NavBar 
      v-if="showMainContent"
      :title="navTitle" 
      :subtext="subtext" 
      :explanation="explanation" 
    />
	<div id="lottie-bg"></div>
	<div class="lottie-animation"></div>
	<div id="lottie">
    <Lottie src="/img/HamwicLottie.json" width="100%" height="100%" @animationComplete="onAnimationComplete"/>
   </div>
    <HamwicToolbox 
      v-if="showMainContent && showToolbox"
      @toolSelected="onToolSelected" 
      @updateContent="updateContent"
    />
	<SignatureGenerator 
      v-if="showMainContent && showSignatureGenerator" 
      @close="handleReturnToToolbox" 
    />
    <PasswordManager 
      v-if="showMainContent && showPasswordManager" 
      @close="handleReturnToToolbox" 
    />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import Lottie from './components/Lottie.vue';
import HamwicToolbox from './components/HamwicToolbox.vue';
import PasswordManager from './components/PasswordManager.vue';
import SignatureGenerator from './components/SigGen.vue';

export default {
  components: {
    NavBar,
	Lottie,
    HamwicToolbox,
    PasswordManager,
	SignatureGenerator,
  },
  data() {
    return {
      showMainContent: false,
      transitionComplete: false,
      showToolbox: true,
      showPasswordManager: false,
      showSignatureGenerator: false,
      navTitle: 'Toolbox',
      subtext: '\u00A0', // Initial subtext
      explanation: '', // Initial explanation
    };
  },
  methods: {
    onAnimationComplete() {
    const lottieDiv = document.getElementById('lottie');
  lottieDiv.classList.add('lottie-animation'); // Add the class to start the animation
  
  // Optional: Hide overlay after a delay
  setTimeout(() => {
    const lottiebg = document.getElementById('lottie-bg');
    lottiebg.style.opacity = '0';
  }, 300); 

  setTimeout(() => {
  const lottiebg = document.getElementById('lottie-bg');
  const lottie = document.getElementById('lottie');
    this.transitionComplete = true;
	lottiebg.style.display = 'none';
	lottie.style.display = 'none';
	this.showMainContent = 'true';
  }, 800);
},
	
    onToolSelected(toolId) {
      if (toolId === 3) {
        this.showToolbox = false;
        this.showSignatureGenerator = true;
      } else {
        console.log(`Tool selected: ${toolId}`);
      }
      if (toolId === 4) {
        this.showToolbox = false;
        this.showPasswordManager = true;
      } else {
        console.log(`Tool selected: ${toolId}`);
      }
    },
    handleReturnToToolbox() {
      this.showToolbox = true;
      this.showPasswordManager = false;
      this.showSignatureGenerator = false;
    },
    updateContent(newTitle, newSubtext, newExplanation) {
      this.navTitle = newTitle;
      this.subtext = newSubtext;
      this.explanation = newExplanation;
    },
  },
};
</script>

<style>
    body {
      overflow-x: hidden;
      position: relative;
      background-color: #f0f0f0;
    }
	
	

@keyframes scaleToCorner {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
 }

.lottie-animation {
  position: fixed;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
  animation: scaleToCorner 1s forwards;
}

	
#lottie {
  scale: 1.5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
	}
	
#lottie-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f0f0f0;
    z-index: 900;
    display: true;
	transition: opacity 0.9s ease;
	transition-delay: 0.1s;
  }


    .tooltitle {
      color: #bca260;
      font-family: 'AvenirLTStd-Roman';
      display: inline;
      font-size: 1.6em;
      font-weight: 500;
      -webkit-font-smoothing: antialiased;
      position: relative;
      top: 0em;
      cursor: default;
    }

    .nav:hover .explain { 
      opacity: 1;
    }

    .explain {
      opacity: 0;
      margin-left: 3em;
      margin-bottom: 0;
      width: 36em;
      padding: -4;
      text-align: justify;
      font-size: 0.8em;
      font-family: 'AvenirLTStd-Roman';
      color: #848484;
      cursor: default;
      transition: opacity 0.75s ease;
      transform: translateY(0.3em);
    }

    .tree {
      position: fixed;
      bottom: 0.5em;
      right: 0;
      width: 15em;
      height: 15em;
      background-size: cover;
      background-position: center;
      opacity: 1;
      z-index: -1;
      animation: fader 2.1s forwards;
    }

    #HET {
      margin-right: 1em;
      margin-left: 8.6em;
      margin-top: -0.1em;
      visibility: visible;
      display: block;
      opacity: 1;
      z-index: 3;
      position: relative;
    }

    .background {
      position: fixed;
      bottom: 0.5em;
      right: 0;
      width: 15em;
      height: 15em;
      background-size: cover;
      background-position: center;
      opacity: 0.5;
      z-index: -5;
    }

    @keyframes fall {
      0% {
        transform: translateY(-100px) rotate(0deg) scale(1);
        opacity: 0.8;
      }
      20% {
        transform: translateY(100px) rotate(72deg) scale(0.8);
        opacity: 0;
      }
      100% {
        transform: translateY(500px) rotate(-120deg) scale(0.6);
        opacity: 0;
      }
    }

    .leaf {
      animation: fall linear infinite;
      animation-duration: 4s;
    }

    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none; 
      scale: 0.5;
      overflow: visible;
      z-index: -6;
      transform: scaleX(-1);
    }

    @keyframes fader {
      0% {
        opacity: 0;
      }
      60% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .subtext {
      color: #848484;
      font-size: 0.5em;
      margin: -0.3em;
      margin-right: 0.1em;
      font-style: italic;
      text-align: right;
      z-index: 90;
      opacity: 1;
    }

    #app {
      font-family: 'Onest', sans-serif;
      font-weight: 200;
    }
</style>
