<template>
  <div id="signature-generator">
    <!-- Signature Preview -->
    <div id="signature-components">
      <div ref="signatureElement" id="email-signature" v-html="generatedSignature"></div>
    </div>
    <div id="params">
      <div class="form-group">
        <label for="school">School</label>
        <input 
          type="search"
          v-model="searchTerm" 
          @input="filterSchools" @keydown.enter="selectFirstSchool"
          placeholder="🔎 Search for a school"
          class="form-control" 
          id="school" 
          autocomplete="off"
        />
        <ul v-if="filteredSchools.length" class="autocomplete-list">
          <li 
            v-for="school in filteredSchools" 
            :key="school.id" 
            @click="selectSchool(school)"
          >
            {{ school.name }}
          </li>
        </ul>
      </div>
      <div class="form-group">
        <label for="name">Name</label>
        <input v-model="name" class="form-control" placeholder="🪪 Your name" id="name" autocomplete="off" list="autocompleteOff" aria-autocomplete="none" readonly onfocus="this.removeAttribute('readonly');" />
      </div>

      <div class="form-group">
        <label for="title">Role</label>
        <input v-model="title" class="form-control" id="title" placeholder="👨🏻‍🏫 Your role" autocomplete="off" list="autocompleteOff" aria-autocomplete="none" readonly onfocus="this.removeAttribute('readonly');" />
      </div>

      <div class="form-group">
        <label for="phone">Phone</label>
        <input v-model="phone" class="form-control" id="phone" placeholder="📞 Your number" autocomplete="off" list="autocompleteOff" aria-autocomplete="none" readonly onfocus="this.removeAttribute('readonly');" />
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input v-model="email" class="form-control" id="email" placeholder="📧 Your email" autocomplete="off" list="autocompleteOff" aria-autocomplete="none" readonly onfocus="this.removeAttribute('readonly');" />
      </div>
	</div>
   <div id="export">
   <p style="margin: 0.8em;" />
        <div id="button-bar">
		<button @click="importToOutlook">Import to Outlook</button>
        <label class="confirmation"></label>
      </div>
      <div id="button-bar">
        <button @click="copySignature" class="btn btn-primary">Copy Signature</button>
        <label v-if="copied" class="confirmation"></label>
      </div>
    <div id="button-bar">
    <button @click="downloadSignatureAsImage">Download as PNG</button><p style="margin: 0.5em;" />
		<span id="disclaim"><label for="disclaim">Disclaimer?</label><input type="checkbox" id="disclaim" name="disclaim" value="disclaim" v-model="includeDisclaimer"></span>
    </div>
   </div>
	<div v-if="outlookInstructions" class="outlook-overlay">
		<div id="closeoutlook" @click="hideOutlookInstructions"><button @click="hideOutlookInstructions" class="closeoutlookvid">Close Instructions</button></div>
      <div class="outlook-video">
        <video autoplay loop muted>
		<source src="img/SigInstructions.webm" type="video/webm" />
		Your browser does not support the video tag.
		</video>
      </div>
    </div>
</div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
  data() {
    return {
      name: '',
      title: '',
      phone: '',
      email: '',
      copied: false,
      outlookInstructions: false,
      searchTerm: '', // For the input field
      schools: [], // Array to hold the schools data
      filteredSchools: [], // Array to hold the filtered schools
      selectedSchool: null,
      schoolSelected: false,
      selectedSchoolId: null,
	  includeDisclaimer: false
    };
  },
  computed: {
    generatedSignature() {
	const cleanedPhone = "+44" + this.phone.slice(1).replace(/\s+/g, '');
      return `
        <table cellpadding="0" cellspacing="0" border="0" style="font-family: Tahoma, Arial, sans-serif; font-size: 9pt; width: 400px;">
          <tr>
            <td width="120" style="padding-right: 10px; vertical-align: top;">
              <img src="${this.selectedSchool?.logo || 'img/school.png'}" style="width: 120px; height: 120px;" />
            </td>
            <td width="460" style="width: 460px; padding-left: 10px; vertical-align: top; mso-line-height-rule: exactly; line-height: 22px; border-left: 3px solid #434343;" valign="top">
              <span style="font-size: 16px; font-weight: bold;">${this.name || 'Your name'}</span><br>
              <span style="font-size: 14px;">${this.title || '‍Your role'}</span><br>
              <span style="font-weight: bold;">T:</span> <a href="tel:${cleanedPhone}" style="text-decoration: none; color: #434343;">${this.phone || 'Your number'}</a><br>
              <span style="font-weight: bold;">E:</span> <a href="mailto:${this.email}" style="text-decoration: none; color: #434343;">${this.email || 'Your email'}</a><br>
              <a href="https://${this.selectedSchool?.url || ''}" style="font-size: 14px; text-decoration: none; color: #434343;">${this.selectedSchool?.name || 'Your school'}</a><br>
            </td>
          </tr>
          <tr>
            <td id="disclaimer" colspan="2" style="color: silver; font-size: 9px; padding-top: 10px; text-align: left;">
              CONFIDENTIALITY NOTICE: This correspondence, including any attachments, is for the sole use of the intended recipient(s) and may contain confidential and privileged information. Any unauthorized review, use, disclosure or distribution is prohibited. There is no intention to create any legally binding contract or other binding commitment through the use of this electronic communication. If you are not the intended recipient, please contact the school and destroy all copies of the correspondence.<br>
              VIRUS WARNING: Although the school has taken reasonable precautions to ensure no viruses are present in this email and any attachments, they cannot accept responsibility for any loss or damage arising from the use of this email or attachments and you are advised to take your own steps to ensure that they are actually virus free.
              <center><a href="https://hamwic.org" style="margin: auto;"><img src="/img/hamwicsiglogo.png" width="18%" /><img src="/img/allaboutthechild.png" width="25%" /></a></center>
            </td>
          </tr>
        </table>
      `;
    },
  },
  methods: {
  
  importToOutlook() {
    this.copySignature();
    this.openOutlookWindow();
	this.outlookInstructions = true;
  },
  
    hideOutlookInstructions() {
  	this.outlookInstructions = false;
  },
    
  openOutlookWindow() {
    const screenWidth = screen.width;
    const screenHeight = screen.height;
    const width = screenWidth * 0.48;
    const height = screenHeight;

    const outlook = window.open(
      'https://outlook.office365.com/mail/options/accounts-category/signatures-subcategory',
      '_blank',
      `height=${height},width=${width},left=10,top=10,scrollbars=yes,menubar=no,location=no`
    );
},
  
  
  
    copySignature() {
      const signature = document.getElementById('email-signature');
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(signature);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      this.copied = true;
      setTimeout(() => (this.copied = false), 3000);
    },
	
    async loadSchools() {
      try {
        const timestamp = new Date().getTime(); // Generate timestamp to fight caching
        const response = await fetch(`schools.list?v=${timestamp}`);
        if (!response.ok) {
          throw new Error('Failed to load schools list');
        }
        const schoolsData = await response.json();
        this.schools = schoolsData;
      } catch (error) {
        console.error('Error loading schools list:', error);
      }
    },

    filterSchools() {
      this.filteredSchools = this.schools.filter(school =>
        school.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
	selectFirstSchool() {
    if (this.filteredSchools.length > 0) {
      this.selectSchool(this.filteredSchools[0]);
    }
  },

    selectSchool(school) {
      this.selectedSchool = school; // Set selected school
      this.searchTerm = school.name; // Set input field to school name
      this.populateFields(); // Populate other fields
      this.filteredSchools = []; // Clear filtered list
	  this.email = this.updateEmail();
      this.selectedSchoolId = school.id;
    },

    populateFields() {
      if (this.selectedSchool) {
        this.name = ''; // Clear or set default if needed
        this.title = ''; // Clear or set default if needed
		this.logo = this.selectedSchool.logo;
        this.phone = this.selectedSchool.phone;
		this.url = this.selectedSchool.url;
      }
    },
	
	updateEmail() {
    if (!this.selectedSchool || !this.name) return '';
    const emailStructure = this.selectedSchool.emailstructure;
    const domain = this.selectedSchool.domain;

    // Split name into first and last names for flexibility
    const [firstName, lastName] = this.name.split(' ');
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const displayLastName = lastName || '❓';

    let emailPrefix;
    switch (emailStructure) {
        case 'FI.SN':
            emailPrefix = `${firstInitial}.${displayLastName}`;
            break;
        case 'FN.SN':
            emailPrefix = `${firstName}.${displayLastName}`;
            break;
        case 'FISN':
            emailPrefix = `${firstInitial}${displayLastName}`;
            break;
		case 'FNSN':
            emailPrefix = `${firstName}${displayLastName}`;
            break;
        default:
            emailPrefix = `${firstName}.${displayLastName}`;
			
    }

    // Update the email field with the dynamically generated address
    this.email = `${emailPrefix.toLowerCase()}${domain}`; 
},


    // Function for filtering schools based on search input
    filterSchools() {
      this.filteredSchools = this.schools.filter(school =>
        school.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
	
	handleClickOutside(event) {
      const autocompleteList = this.$el.querySelector('.autocomplete-list');
      const inputBox = this.$el.querySelector('#school');
      if (autocompleteList && !autocompleteList.contains(event.target) && !inputBox.contains(event.target)) {
        this.filteredSchools = [];
      }
    },
	
// use this?
async convertImagesToBase64(signatureHtml) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = signatureHtml;
    const images = tempDiv.getElementsByTagName('img');

    const imagePromises = Array.from(images).map(async (img) => {
        const imageUrl = img.src;

        if (!imageUrl.startsWith('data:')) { // Check if it's not already a Base64 image
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Failed to load image at ${imageUrl}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();

            return new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    img.src = reader.result; // Set the src to Base64
                    resolve();
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }
    });

    // Wait for all images to be converted
    await Promise.all(imagePromises);

    // Return the modified HTML as a string
    return tempDiv.innerHTML;
},



  downloadSignatureAsImage() {
    const signatureElement = this.$refs.signatureElement;

    // Check the checkbox state and select either the first or all <tr> elements
    const rowsToCapture = this.includeDisclaimer
      ? signatureElement.querySelectorAll('tr')  // Capture all rows if checked
      : [signatureElement.querySelector('tr')]; // Only capture the first row if unchecked

    // If there are no rows to capture, return early
    if (!rowsToCapture.length) {
      console.error('No rows found in the signature element');
      return;
    }

    // Capture the selected rows to canvas images
    Promise.all(
      Array.from(rowsToCapture).map(row =>
        html2canvas(row, { backgroundColor: null })
      )
    )
    .then(canvases => {
      // Combine canvases if capturing multiple rows
      if (canvases.length > 1) {
        const combinedCanvas = document.createElement('canvas');
        const context = combinedCanvas.getContext('2d');

        // Set canvas size to fit all rows vertically
        combinedCanvas.width = canvases[0].width;
        combinedCanvas.height = canvases.reduce((sum, canvas) => sum + canvas.height, 0);

        let yOffset = 0;
        canvases.forEach(canvas => {
          context.drawImage(canvas, 0, yOffset);
          yOffset += canvas.height;
        });

        // Convert combined canvas to data URL
        const imgData = combinedCanvas.toDataURL('image/png');
        this.downloadImage(imgData, 'signature.png');
      } else {
        // Single row capture: download directly from the first canvas
        const imgData = canvases[0].toDataURL('image/png');
        this.downloadImage(imgData, 'signature.png');
      }
    })
    .catch(error => {
      console.error('Error capturing the signature as an image:', error);
    });
  },

  // Helper function for downloading the image
  downloadImage(dataUrl, filename) {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;

    // Trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }












	
	
},

 
  
  mounted() {
    this.loadSchools();
	document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  
  watch: {
    // Watch name and selectedSchool to dynamically update the email
    name() {
      this.updateEmail();
    },
    selectedSchool() {
      this.updateEmail();
    },
  },
};
</script>

<style scoped>
#signature-generator {
  padding: 2em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-family: 'Roboto', sans-serif;
  align-items: top;
  gap: 2vw;
}

/* Keyframes for sliding in with a bounce and wobble */
@keyframes slideInBounce {
  0% {
    transform: translateX(-100%) skewX(0deg);
    opacity: 0;
  }
  50% {
    transform: translateX(15%) skewX(2deg);
    opacity: 1;
  }
  70% {
    transform: translateX(-5%) skewX(-1.5deg);
  }
  85% {
    transform: translateX(3%) skewX(1.5deg);
  }
  100% {
    transform: translateX(0) skewX(0deg);
    opacity: 1;
  }
}

/* Applying the animations with staggered delays */

.form-control {
text-overflow: ellipsis;
}

#signature-components {
  opacity: 0;
  display: flex;
  z-index: 5;
  animation: slideInBounce 1s ease-out forwards;
  animation-delay: 1s; /* Delay to create a sequence */
}

#params {
  opacity: 0;
  display: table;
  width: 3em;
  margin-left: 1.5em;
  height: max-content;
  padding: 2em;
  box-shadow: -0.3em 0.3em 0.3em 0.3em rgba(0.2, 0.2, 0.2, 0.2);
  background-color: #fff;
  border-radius: 1vh;
  z-index: 3;
  animation: slideInBounce 1s ease-out forwards;
  animation-delay: 1.5s; /* Delay to create a sequence */
  border-bottom: 4px solid #8F7212;
}

#export {
  opacity: 0;
  display: table;
  width: 3em;
  margin-left: 1.5em;
  height: max-content;
  padding: 1em;
  box-shadow: -0.3em 0.3em 0.3em 0.3em rgba(0.2, 0.2, 0.2, 0.2);
  background-color: #fff;
  border-radius: 1vh;
  z-index: 1;
  animation: slideInBounce 1s ease-out forwards;
  animation-delay: 2s; /* Additional delay for stagger effect */
  border-bottom: 4px solid #8F7212;
}


#email-signature {
  box-shadow: -0.3em 0.3em 0.3em 0.3em rgba(0.2, 0.2, 0.2, 0.2);
  background-color: #fff;
  display: block;
  padding: 1em;
  width: max-content;
  border-radius: 1vh;
  border-bottom: 4px solid #8F7212;
}

#button-bar {
padding-bottom: 1em;
}

.form-group {
  display: flex;
  padding: 0.3em;
  flex-direction: column;
}

.confirmation {
}

.instructions {
  margin-top: 10px;
}

.autocomplete-list {
  border: 1px solid #ccc;
  max-height: 7em;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  position: absolute;
  transform: translateX(11em);
  z-index: 10; /* Make sure it's above other elements */
  width: 13em; /* Match the input width */
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.autocomplete-list li {
  padding: 0.5em;
  cursor: pointer;
  overflow-x: visible;
}

.autocomplete-list li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.outlook-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: right;
  justify-content: right;
  z-index: 999;
}

video {
        height: 100vh;
        width: 100%;
        object-fit: cover;
        display: flex;
		z-index: 1000;
    }
	
	
#closeoutlook {
  position: absolute;
  top: 50%;
  left: 20%;
}

.closeoutlookvid {
  height: 4em;
  background-color: #ffffff;
  color: #333;
  padding: 1em 2em;
  border: none;
  border-radius: 1em;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.closeoutlookvid:hover {
  background-color: #e0e0e0; /* Slight color change on hover */
}


#disclaim {
font-size: 0.65em;
padding-left: 0.1em;
vertical-align: middle;
}

@media (max-width: 1024px) {
  #signature-generator {
    display: grid; /* Use grid layout */
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    padding: 0em;
	margin-left: -3%;
	left: -3%;
    padding-top: 10vh;
    gap: 1.5em;
    font-family: 'Roboto', sans-serif;
	justify-items: space-between;
	align-content: stretch;
  }
  
  #params, #export {
    grid-column: span 1; /* Each will take one column */
    padding: 1em;
    box-shadow: -0.3em 0.3em 0.3em rgba(0.2, 0.2, 0.2, 0.2);
    background-color: #fff;
    border-radius: 1vh;
    border-bottom: 4px solid #8F7212;
    text-align: center; /* Center text in each cell */
	width: 67%;
	align-content: center;
  }

  #button-bar {
  scale: 1.2;
  padding: 0.5em;
  padding-bottom: 1.5em;
  }

  #signature-components {
    grid-column: span 2; /* This will take up two columns */
    display: flex; /* Flexbox for horizontal alignment, if needed */
    flex-direction: row; /* Align items in a row */
    width: 100%; /* Ensure it takes full width */
  }

  .outlook-overlay {
    display: none;
  }
}

</style>
